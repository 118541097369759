//TODO Need to remove legacy colors after design will be done and variables will be removed from everywhere in the project
import {colors} from './colors';

export const legacyColors = {
	neutral6: colors.neutral.neutral6,
	neutral12: colors.neutral.neutral12,
	neutral16: colors.neutral.neutral16,
	neutral24: colors.neutral.neutral24,
	neutral40: colors.neutral.neutral40,
	neutral56: colors.neutral.neutral56,
	neutral72: colors.neutral.neutral72,
	neutral94: colors.neutral.neutral94,
	neutral98: colors.neutral.neutral98,

	primary48: colors.primary.primary48,
	primary72: colors.primary.primary72,
	primary80: colors.primary.primary80,
	primary92: colors.primary.primary92,
	primary98: colors.primary.primary98,

	secondary48: colors.secondary.secondary48,
	secondary80: colors.secondary.secondary80,
	secondary92: colors.secondary.secondary92,

	green92: colors.green.green92,

	orange92: colors.orange.orange92,

	red56: colors.red.red56,
	red92: colors.red.red92,
	red98: colors.red.red98,
	red99: colors.red.red99,

	yellow48: colors.yellow.yellow48,
	yellow80: colors.yellow.yellow80,
	yellow96: colors.yellow.yellow96,

	blue88: colors.blue.blue88,
	blue40: colors.blue.blue40,
};
