export const dataRoutes = {
	schools: '/data/schools',
	preAssess: '/data/pre-assess',
	teachers: '/data/teachers',
	schoolSpecialists: '/data/school-specialists',
	districtSpecialists: '/data/district-specialists',
	classes: '/data/classes',
	groups: '/data/groups',
	specialistGroups: '/data/specialist-groups',
	students: '/data/students',
};
