import {GridBox} from '@esgi/ui/layout';
import {CenteredText, Container, DiagramBox, DiagramInfoBox, Divider, LegendBox} from './index.styled';
import {LegendWithTranscript} from '../../../../../../legend-with-transcript';
import {SessionList} from '../../../session-list';
import {ItemAnalysisDiagram, ItemAnalysisDiagramDetailed} from '../../../../../../item-analysis-diagram';
import {YAxisLabel, allSessionsSelectedMessage} from '../../constants';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {DiagramCheckboxes} from '../diagram-checkboxes';
import {SynchronizedStateProps} from '../../types';
import {useState} from 'react';

export function FullScreenContent({
	testInfo,
	isAllSessionsChecked,
	isDetailedViewChecked,
	toggleAllSessionsCheckbox,
	toggleDetailedViewCheckbox,
	isAllSessionsSelected,
	selectedSessionIds,
	sessionItems,
	setSelectedSessionIds,
	selectedSessions,
	diagramLegendItems,
	diagramPageIndex,
	setDiagramPageIndex,
	isTablet,
}: SynchronizedStateProps) {
	const [visibleSessionItemsCount, setVisibleSessionItemsCount] = useState(15);

	return (
		<Container>
			<GridBox flow='column' justify='between' align='center' gap='6'>
				<GridBox flow='column' align='center'>
					<DiagramCheckboxes
						isAllSessionsChecked={isAllSessionsChecked}
						isDetailedViewChecked={isDetailedViewChecked}
						toggleAllSessionsCheckbox={toggleAllSessionsCheckbox}
						toggleDetailedViewCheckbox={toggleDetailedViewCheckbox}
					/>
				</GridBox>

				<GridBox>
					<LegendBox>
						{diagramLegendItems.map((props, index) => (
							<LegendWithTranscript key={index} {...props} />
						))}
					</LegendBox>
				</GridBox>
			</GridBox>

			<Divider />

			<DiagramInfoBox>
				{isAllSessionsSelected ? (
					<CenteredText size='small' font='mono' color='lowContrast'>
						{allSessionsSelectedMessage}
					</CenteredText>
				) : (
					<SessionList
						selectedSessionIds={selectedSessionIds}
						setSelectedSessionIds={setSelectedSessionIds}
						sessionItems={sessionItems}
						type='multiple'
						indicatorPercentageType
						visibleSessionItemsCount={visibleSessionItemsCount}
						setVisibleSessionItemsCount={setVisibleSessionItemsCount}
					/>
				)}

				<DiagramBox justifyCentered={!isDetailedViewChecked}>
					<OverlayScrollbarsComponent
						defer
						options={{
							scrollbars: {autoHide: 'leave'},
							paddingAbsolute: true,
						}}
					>
						{isDetailedViewChecked ? (
							<ItemAnalysisDiagramDetailed
								sessions={selectedSessions}
								testInfo={testInfo}
								YAxisLabel={YAxisLabel}
								barWidth={376}
								barHeight={16}
								barsRowGap={24}
								diagramWidth='calc(100% - 12px)'
								pageIndex={diagramPageIndex}
								onPageIndexChanged={setDiagramPageIndex}
								isTablet={isTablet}
							/>
						) : (
							<ItemAnalysisDiagram
								sessions={selectedSessions}
								testInfo={testInfo}
								YAxisLabel={YAxisLabel}
								barLineWidth={16}
								diagramWidth={812}
								diagramHeight={352}
								withCartesianGrid
								pageIndex={diagramPageIndex}
								onPageIndexChanged={setDiagramPageIndex}
							/>
						)}
					</OverlayScrollbarsComponent>
				</DiagramBox>
			</DiagramInfoBox>
		</Container>
	);
}
