import {styled} from '@esgi/ui/theme';
import {Select} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';

export const SelectField = styled(Select.Field, {
	width: '300px',
	overflow: 'hidden',
});

export const SelectOption = styled(Select.Option, {
	'&:hover': {
		background: '$primaryBackground',

		'& span': {
			[`& > ${Text}`]: {
				color: '$blue40',
			},
		},
	},
});