import {useUser} from '@esgi/core/authentication';
import {Class, Group, Student} from '@esgi/main/libs/store';
import {useCallback, useEffect, useState} from 'react';
import {getSectionModePermissionsData} from '../utils/get-section-mode-permissions-data';
import {StudentSectionMode, getStudentSectionModePermissionValue, updateStudentSectionModePermissions} from '../types';
import {SingleSelectState} from '@esgi/main/features/teacher/students-panel';

export function usePanelState({
	classList,
	groupList,
	studentList,
	selectedClassId,
	selectedGroupId,
	selectedStudentId,
}: {
	classList: Class[];
	groupList: Group[];
	studentList: Student[];
	selectedClassId: Class['id'] | null;
	selectedGroupId: Group['id'] | null;
	selectedStudentId: Student['id'] | null;
}) {
	const user = useUser();

	const [selected, setSelected] = useState<SingleSelectState>({
		classId: selectedClassId,
		groupId: selectedGroupId,
		studentId: selectedStudentId,
		studentFromClassId: null,
		studentFromGroupId: null,
	});

	const [hotfixApplied, setHotfixApplied] = useState(false);

	useEffect(() => {
		if(!hotfixApplied && classList.length) {
			setSelected(prev => ({
				...prev,
				studentFromClassId: classList.find(({studentIDs}) => studentIDs.includes(selectedStudentId))?.id,
			}));
			setHotfixApplied(true);
		}
	}, [hotfixApplied, classList]);

	const [sectionMode, setSectionMode] = useState(StudentSectionMode.View);

	const [sectionModePermissions, setSectionModePermissions] = useState(
		getSectionModePermissionsData({
			classes: classList,
			groups: groupList,
			students: studentList,
			canAdd: Boolean(user?.canAddStudents),
			canEdit: Boolean(user?.canEditStudents),
			canManageClasses: Boolean(user?.canManageClasses),
		}),
	);

	useEffect(() => {
		setSectionModePermissions(
			getSectionModePermissionsData({
				classes: classList,
				groups: groupList,
				students: studentList,
				canAdd: Boolean(user?.canAddStudents),
				canEdit: Boolean(user?.canEditStudents),
				canManageClasses: Boolean(user?.canManageClasses),
			}),
		);
	}, [classList, groupList, studentList, user?.canManageClasses, user?.canEditStudents, user?.canAddStudents]);

	const updateSectionModePermissions: updateStudentSectionModePermissions = useCallback(
		({sectionMode, tabId, newPermissions}) => {
			setSectionModePermissions((currentPermissions) => ({
				...currentPermissions,
				[sectionMode]: {
					...currentPermissions[sectionMode],
					[tabId]: newPermissions,
				},
			}));
		},
		[],
	);

	const getSectionModePermissionValue: getStudentSectionModePermissionValue = useCallback(
		({sectionMode, tabId}) => sectionModePermissions[sectionMode][tabId],
		[sectionModePermissions],
	);

	const setSectionViewMode = useCallback(() => {
		setSectionMode(StudentSectionMode.View);
	}, []);

	return {
		selected,
		sectionMode,
		setSectionMode,
		sectionModePermissions,
		setSelected,
		setSectionViewMode,
		updateSectionModePermissions,
		getSectionModePermissionValue,
	};
}
