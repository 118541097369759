import {TestSession, TestInfo} from '../../../../../../types';
import {useDiagramData} from '../../hooks/use-diagram-data';
import {useVisibleData} from '../../hooks/use-visible-data';
import {pageSize} from './constants';
import {EmptyDataMessage} from '../empty-data-message';
import {DiagramContainer, Pagination, RightableText, SessionNoteText, SubHeaderRowBox} from './index.styled';
import {HeaderRow} from './components/header-row';
import {isNull} from 'underscore';
import {TestContentRow} from './components/test-content-row';
import {SessionsCounterAxis} from './components/sessions-counter-axis';
import {Property} from '@stitches/react/types/css';
import {Dispatch} from 'react';

type Props = {
	sessions: TestSession[];
	/**
	 * Prop is uses for class entity. If emptySessionsCount is number, sessions count will be increase on passed value.
	 */
	emptySessionsCount?: number;
	testInfo: TestInfo;
	YAxisLabel: string;
	barWidth: number;
	barHeight: number;
	barsRowGap: Property.RowGap;
	diagramWidth?: Property.Width;
	pageIndex: number;
	onPageIndexChanged: Dispatch<number>;
	isTablet: boolean;
};

export function DetailedDiagram({
	sessions,
	testInfo,
	YAxisLabel,
	emptySessionsCount = 0,
	barWidth,
	barHeight,
	barsRowGap,
	diagramWidth,
	pageIndex,
	onPageIndexChanged,
	isTablet,
}: Props) {
	const {data, sessionsCount} = useDiagramData({sessions, testInfo, emptySessionsCount});

	const {visibleData, currentPageIndex, totalPages, nextPage, previousPage, sessionNote} = useVisibleData({
		data,
		pageSize,
		sessions,
		pageIndex,
		onPageIndexChanged,
	});

	if (!data.length) {
		return <EmptyDataMessage />;
	}

	return (
		<DiagramContainer
			css={{
				rowGap: barsRowGap,
				width: diagramWidth,
			}}
		>
			<HeaderRow YAxisLabel={YAxisLabel} />

			<SubHeaderRowBox>
				<SessionsCounterAxis sessionsCount={sessionsCount} axisWidth={barWidth - 8} />
			</SubHeaderRowBox>

			{visibleData.map((dataItem, index) => {
				if (isNull(dataItem)) {
					return null;
				}

				const {correct, incorrect, questionTitle, questionID} = dataItem;

				return (
					<TestContentRow
						correct={correct}
						incorrect={incorrect}
						questionNumber={currentPageIndex * pageSize + index + 1}
						questionTitle={questionTitle}
						sessionsCount={sessionsCount}
						barWidth={barWidth}
						barHeight={barHeight}
						key={questionID}
						isTablet={isTablet}
					/>
				);
			})}

			{totalPages > 1 && (
				<Pagination
					currentPageIndex={currentPageIndex}
					nextPage={nextPage}
					previousPage={previousPage}
					totalPages={totalPages}
				/>
			)}

			{sessionNote && (
				<>
					<RightableText size='xSmall' font='mono' color='lowContrast'>
						Notes
					</RightableText>
					<SessionNoteText size='xSmall' font='mono' color='neutral40'>
						{sessionNote}
					</SessionNoteText>
				</>
			)}
		</DiagramContainer>
	);
}
