import {Buttons} from '@esgillc/ui-kit/button';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {ModalManagerRefObject} from '@esgillc/ui-kit/modal';
import moment from 'moment';
import React, {createRef, ReactNode} from 'react';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TestType} from '@esgi/core/enums';
import {TestCopied} from 'shared/modules/test-details/events';
import {Modal, ModalBody, ModalFooter} from '@esgi/deprecated/ui-kit/modal';
import {AfterCopyAlert} from '../../kits/test-details/dialogs/copy-test/after-copy-alert';
import {CopyTestModal} from '../../kits/test-details/dialogs/copy-test/copy-test';
import {
	ContentAreaField,
	DescriptionField,
	GradeLevelField,
	Item,
	ShareTestField,
	StateStandardField,
	SubjectTabField,
	TestTypeField,
} from '../../kits/test-details';
import {TestInfo} from '../common/models';
import RubricEditorModal from './components/edit-dialog/rubric-editor';
import Header from './components/header/header';
import MiniPreviewForm from './components/mini-preview-form/mini-preview-form';
import PreviewModal from './components/preveiw-dialog/preview-modal';
import {SubjectModel} from './models';
import RubricDetailsService from './rubric-details-service';
import styles from './styles.module.less';
import DeletedTestSessionsExistModal
	from 'modules/assets/tests/rubric/details/components/deleted-test-sessions-exist-modal/deleted-test-sessions-exist-modal';
import {EventBusDispatcher} from '@esgillc/events';
import {Form, FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {userStorage} from '@esgi/core/authentication';
import isEqual from 'lodash/isEqual';
import {RemoveStandardDialog} from '@esgi/ui';

interface Props {
	testID: number;
	disableAddToSubject?: boolean;
	disableCopy?: boolean;
	close: (testInfo?: TestInfo & {criteriaCount: number}) => void;
	onTestCreated?: (closeModal?: boolean) => void
}

class State {
	contentAreas: Item[] = [];
	subjects: SubjectModel[] = [];
	stateStandardNames: string;
	starred: boolean;
	hidden: boolean;
	author: string;
	createDate: string;
	canShare: boolean;

	loaded: boolean;
	showEditModal: boolean = false;
	showPreviewModal: boolean = false;
	showCopyFlow: boolean = false;
	showAfterCopyModal: boolean = false;
	showDeletedTestSessionsExistAlert: boolean = false;
	rubricCopyID: number = 0;

	readonly: boolean;

	showRemoveStandardDialog: boolean = false;
	verificationModalWasShown: boolean = false;
}


export default class RubricDetailsModal extends React.PureComponent<Props, State> {
	public readonly state = new State();
	private readonly modalManagerRef: ModalManagerRefObject = createRef();
	private readonly rubricDetailsService: RubricDetailsService = new RubricDetailsService();

	private readonly form = new FormGroup({
		name: new FormControl('', {validators: [Validators.required(), Validators.length.max(65)]}),
		description: new FormControl<string>('', {validators: [Validators.required(), Validators.length.max(140)]}),
		contentArea: new FormControl<number[]>([]),
		color: new FormControl<string>(''),
		grade: new FormControl<number[]>([]),
		stateStandard: new FormControl<Item>({} as Item),
		stateStandardIDs: new FormControl<number[]>([]),
		share: new FormControl<boolean>(false),
	});

	private clearStateStandards = () => {
		this.form.controls.stateStandard.value = {id: null, name: ''};
		this.form.controls.stateStandardIDs.value = [];
		this.setState({stateStandardNames: ''});
	};

	public componentDidMount() {
		this.initDetails(this.props.testID);
		this.form.controls.contentArea.onChanged.subscribe((observer) => {
			const isContentAreaRemoved = observer.prevState.value.length > observer.currState.value.length;
			const isContentAreaChanged = observer.prevState.value.length && !isEqual(observer.prevState.value, observer.currState.value);

			if (isContentAreaRemoved || isContentAreaChanged) {
				this.clearStateStandards();
			}
		});
		this.form.controls.grade.onChanged.subscribe((observer) => {
			const isGradeLevelRemoved = observer.prevState.value.length > observer.currState.value.length;
			const isGradeLevelChanged = observer.prevState.value.length && !isEqual(observer.prevState.value, observer.currState.value);

			if (isGradeLevelRemoved || isGradeLevelChanged) {
				this.clearStateStandards();
			}
		});
	}

	private onStandardAffectingChange = () => {
		if (!this.form.controls.stateStandardIDs.value.length || this.state.verificationModalWasShown) {
			return;
		}
		this.setState({showRemoveStandardDialog: true});
	};

	private onRemoveStandardDialogClose = () => {
		this.setState({showRemoveStandardDialog: false});
	};

	private onRemoveStandardDialogContinue = () => {
		this.setState({
			showRemoveStandardDialog: false,
			verificationModalWasShown: true,
		});
	};

	public render() {
		return (
			<>
				<ServiceLoader trackingService={this.rubricDetailsService} fullscreen/>
				{this.state.loaded &&
					<>
						{this.renderDetails()}
						{this.renderEditModal()}
						{this.renderPreviewModal()}
						{this.renderCopyTestModal()}
						{this.renderAfterCopyModal()}
						{this.renderDeletedTestSessionsExistModal()}
						{this.state.showRemoveStandardDialog && <RemoveStandardDialog
							onClose={this.onRemoveStandardDialogClose}
							onContinueEditing={this.onRemoveStandardDialogContinue}
						/>}
					</>
				}
			</>
		);
	}

	public renderDetails() {
		if (!this.state.loaded) {
			return;
		}

		const {name, description, contentArea, color, grade, stateStandard, share} = this.form.controls;
		const {author, starred, createDate, hidden, readonly} = this.state;

		return (
			<Modal modalManagerRef={this.modalManagerRef}>
				<ModalBody className={styles.body}>
					<Form
						controller={this.form}
						className={styles.form}
					>
						<Header
							nameControl={name}
							readonly={readonly}
							author={author}
							starred={starred}
							hidden={hidden}
							canCopy={!this.props.disableCopy}
							createDate={createDate}
							service={this.rubricDetailsService}
							onHideClicked={() => this.setState({hidden: !this.state.hidden}, () => this.rubricDetailsService.updateHide(this.state.hidden))}
							onStarClicked={() => this.setState({starred: !this.state.starred}, () => this.rubricDetailsService.updateStar(this.state.starred))}
							onCopyClicked={() => this.setState({showCopyFlow: true})}
						/>
						<div className={styles.content}>
							<div className={styles.leftPanel}>
								<div className={styles.testMetaInfo}>
									<TestTypeField
										readonly={readonly}
										type={TestType.Rubric}
									/>
									<DescriptionField
										readonly={readonly}
										control={description}
									/>
									<ContentAreaField
										readonly={readonly}
										contentAreaControl={contentArea}
										colorControl={color}
										contentAreas={this.state.contentAreas}
										stateStandard={stateStandard}
										onStandardAffectingChange={this.onStandardAffectingChange}
									/>
									<GradeLevelField
										readonly={readonly}
										control={grade}
										stateStandard={stateStandard}
										onStandardAffectingChange={this.onStandardAffectingChange}
									/>
									{Boolean(this.state.stateStandardNames) && <StateStandardField
										readonly={readonly}
										stateStandardNames={this.state.stateStandardNames}
										control={stateStandard}
									/>}
									{this.state.canShare && !readonly && <ShareTestField control={share}/>}
								</div>
								{!this.props.disableAddToSubject &&
									<SubjectTabField
										testID={this.rubricDetailsService.testID}
										testName={() => name.value}
									/>
								}
							</div>
							<div className={styles.rightPanel}>
								<div className={styles.previewContainer}>
									<div className={styles.actions}>
										{!readonly &&
											<Buttons.Contained onClick={() => {
												if (!this.state.verificationModalWasShown && this.form.controls.stateStandardIDs.value.length) {
													this.onStandardAffectingChange();
													return;
												}

												this.setState({showEditModal: true});
											}}>
												EDIT TEST
											</Buttons.Contained>
										}
										<Buttons.Contained onClick={() => this.setState({showPreviewModal: true})}>
											<svg
												width='11'
												height='14'
												viewBox='0 0 11 14'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path d='M0 0V14L11 7L0 0Z' fill='#F2F2F2'/>
											</svg>
											PREVIEW
										</Buttons.Contained>
									</div>
									<div className={styles.preview}>
										<div className={styles.previewContent}>
											<MiniPreviewForm rubricService={this.rubricDetailsService}/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Buttons.Gray onClick={() => this.closeModal()}>
						{readonly ? 'CLOSE' : 'CANCEL'}
					</Buttons.Gray>
					{!readonly &&
						<Buttons.Contained onClick={() => this.saveAndClose()}>
							SAVE AND CLOSE
						</Buttons.Contained>
					}
				</ModalFooter>
			</Modal>
		);
	}

	private renderEditModal(): ReactNode {
		if (this.state.showEditModal) {
			return (
				<RubricEditorModal
					detailsService={this.rubricDetailsService}
					onCancelClicked={() => this.setState({showEditModal: false})}
					onSaveClicked={() => {
						this.clearStateStandards();
						this.setState({showEditModal: false});
					}}
				/>
			);
		}
	}

	private renderPreviewModal(): ReactNode {
		if (this.state.showPreviewModal) {
			return (
				<PreviewModal
					rubricService={this.rubricDetailsService}
					onCloseClicked={() => this.setState({showPreviewModal: false})}
				/>
			);
		}
	}

	private renderCopyTestModal(): ReactNode {
		if (this.state.showCopyFlow) {
			return (
				<CopyTestModal
					testName={this.rubricDetailsService.testInfo$.value.name}
					onSaveClicked={(name) => this.handleCopy(name)}
					onCloseClicked={() => this.setState({showCopyFlow: false})}
					onTestCreated={this.props.onTestCreated?.bind(this, false)}
				/>
			);
		}
	}

	private renderAfterCopyModal(): ReactNode {
		if (this.state.showAfterCopyModal) {
			return (
				<AfterCopyAlert
					onCancelClicked={() => this.setState({showAfterCopyModal: false})}
					onViewDetailsClicked={() => this.setState({showAfterCopyModal: false}, () => this.initDetails(this.state.rubricCopyID))}
				/>
			);
		}
	}

	private renderDeletedTestSessionsExistModal(): ReactNode {
		if (this.state.showDeletedTestSessionsExistAlert) {
			return (
				<DeletedTestSessionsExistModal
					onCloseClicked={() => this.setState({showDeletedTestSessionsExistAlert: false})}
					onSaveClicked={() => this.saveAndClose(true)}
				/>
			);
		}
	}

	private initDetails = (testID: number): void => {
		this.rubricDetailsService
			.init(testID)
			.pipe(map(r => {
				let stateStandardNames = '';
				let stateStandardIDs: number[] = [];
				if (r.rubricModel.stateStandards && r.rubricModel.stateStandards.length > 0){
					const currentUser = userStorage.get();
					const userStateStandards = r.rubricModel.stateStandards?.filter(({stateID}) => stateID === currentUser.stateID);
					const commonCoreStandards = r.rubricModel.stateStandards?.filter(({stateID}) => stateID === 0);
					stateStandardNames = (userStateStandards.length ? userStateStandards : commonCoreStandards).map(({name}) => name).join(',');
					stateStandardIDs = r.rubricModel.stateStandards.map(({id}) => id);
				}
				this.setState({
					author: r.author,
					readonly: r.readonly,
					createDate: moment(r.createDate).format('MM/DD/YYYY'),
					starred: r.favorite,
					hidden: r.hidden,
					contentAreas: r.contentAreas,
					subjects: r.subjects,
					loaded: true,
					rubricCopyID: 0,
					canShare: r.canShare,
					stateStandardNames,
				}, () => {
					this.form.value = {
						name: r.rubricModel.name,
						description: r.rubricModel.description,
						color: r.rubricModel.color,
						grade: r.rubricModel.gradeLevelIDs,
						share: r.rubricModel.shared,
						contentArea: [r.rubricModel.contentAreaID],
						stateStandard: {
							id: r.rubricModel.stateStandardID || 0,
							name: stateStandardNames,
						} as Item,
						stateStandardIDs,
					};
				});
			}))
			.subscribe();
	};

	private saveAndClose = (saveAnyway?: boolean): void => {
		this.form.validate().subscribe(v => {
			if (v.valid) {
				const formValue = this.form.value;
				const testInfo: TestInfo = {
					testID: this.rubricDetailsService.testInfo$.value.testID,
					name: formValue.name,
					description: formValue.description,
					gradeLevelIDs: formValue.grade,
					color: formValue.color,
					contentAreaID: formValue.contentArea[0],
					stateStandard: formValue.stateStandard.name === '' ? null : formValue.stateStandard.name,
					stateStandardID: formValue.stateStandard.id ?? 0,
					shared: formValue.share,
					stateStandardIDs: formValue.stateStandardIDs,
				};

				this.rubricDetailsService
					.saveChanges(testInfo, saveAnyway)
					.subscribe((structureChanged) => {
						if (structureChanged) {
							this.setState({showDeletedTestSessionsExistAlert: true});
						} else {
							this.closeModal();
						}
					});
			}
		});
	};

	private closeModal() {
		const testInfo = this.rubricDetailsService.testInfo$.value;
		this.modalManagerRef.current.close(() => {
			if (this.state.rubricCopyID) {
				this.props.onTestCreated();
			} else {
				this.props.close({...testInfo, criteriaCount: this.rubricDetailsService.criteria$.value.length});
			}
		});
	}

	private handleCopy(name: string): Observable<any> {
		return this.rubricDetailsService.copy(name).pipe(map(copyID => {
			this.setState({showAfterCopyModal: true, rubricCopyID: copyID}, () => {
				const {firstName, lastName} = userStorage.get();
				const rubricModel = this.rubricDetailsService.serialize();
				EventBusDispatcher.dispatch(TestCopied, TestCopied(
					name,
					copyID,
					`${firstName} ${lastName}`,
					this.state.createDate,
					0,
					rubricModel.color,
					rubricModel.description,
					this.state.contentAreas.find(c => c.id === rubricModel.contentAreaID)?.name,
					rubricModel.gradeLevelIDs,
					'Rubric',
					[],
					rubricModel.stateStandardName,
					false,
					rubricModel.stateStandardIDs,
				));
			});
		}));
	}
}
