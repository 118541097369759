import {ComponentPropsWithoutRef, ReactNode, forwardRef, useMemo} from 'react';
import {LegendWithTranscript, LegendWithTranscriptItem} from '../legend-with-transcript';
import {Container, FullScreenButton, Header, LegendBox} from './index.styled';
import {AspectRatio} from '@esgi/ui';
import {Box, FlexBox} from '@esgi/ui/layout';
import {isNull} from 'underscore';

type WithFullScreenButton = {
	withFullScreenButton: true;
	onFullScreenButtonClick: VoidFunction;
};

type WithoutFullScreenButton = {
	withFullScreenButton?: false | undefined;
};

type FullScreenButtonProps = WithFullScreenButton | WithoutFullScreenButton;

type Props = ComponentPropsWithoutRef<'div'> & {
	leftHeaderContent?: ReactNode | undefined;
	legendItems: LegendWithTranscriptItem[];
} & FullScreenButtonProps;

export const DiagramBox = forwardRef<HTMLDivElement, Props>(
	({legendItems, children, leftHeaderContent, ...props}, forwardedRef) => {
		const {fullScreenButtonProps, containerProps} = useMemo(() => {
			if (props.withFullScreenButton) {
				const {withFullScreenButton, onFullScreenButtonClick, ...containerProps} = props;

				return {
					fullScreenButtonProps: {
						onFullScreenButtonClick,
					},
					containerProps,
				};
			}

			const {withFullScreenButton, ...containerProps} = props;

			return {
				fullScreenButtonProps: null,
				containerProps,
			};
		}, [props]);

		return (
			<Container ref={forwardedRef} {...containerProps}>
				<Header withLeftHeaderContent={Boolean(leftHeaderContent)}>
					{leftHeaderContent && <FlexBox>{leftHeaderContent}</FlexBox>}
					<LegendBox>
						{legendItems.map((props, index) => (
							<LegendWithTranscript key={index} {...props} />
						))}

						{/* TODO: Should be uncomment in the next phase  */}
						{/* {!isNull(fullScreenButtonProps) && (
							<FullScreenButton color='secondary' onClick={fullScreenButtonProps.onFullScreenButtonClick}>
								<AspectRatio />
							</FullScreenButton>
						)} */}
					</LegendBox>
				</Header>
				<Box>{children}</Box>
			</Container>
		);
	},
);
