import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Button} from '@esgi/ui';

export const Wrapper = styled(GridBox, {
	gap: '$3',
	height: '100%',
	gridTemplateRows: 'auto 1fr auto',
	overflow: 'hidden',
});

export const IconButton = styled(Button.Icon, {
	transition: 'width .3s, height .3s, border-radius .3s, background-color .3s',
	width: '28px',
	height: '28px',
	borderRadius: '6px',
	backgroundColor: '$surface',
});
