import {CenteredText} from './index.styled';
import {activeDescription, detailedViewDescription} from './constants';

type Props = {
	isDetailedView: boolean;
};

export function ActiveSelectedDiagramDescription({isDetailedView}: Props) {
	return (
		<>
			{(isDetailedView ? detailedViewDescription : activeDescription).map((textValue) => (
				<CenteredText size='small' font='mono' color='lowContrast' key={textValue}>
					{textValue}
				</CenteredText>
			))}
		</>
	);
}
