import {styled} from '@esgi/ui/theme';
import {Select} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';

export const SelectField = styled(Select.Field, {
	'& > :first-child': {
		width: 200,
		overflow: 'hidden',

		'span:last-child': {
			overflow: 'hidden',
    	textOverflow: 'ellipsis',
		},
	},
});

export const SelectOption = styled(Select.Option, {
	'&:hover': {
		background: '$primaryBackground',

		'& span': {
			[`& > ${Text}`]: {
				color: '$blue40',
			},
		},
	},
});
