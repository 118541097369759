import {dataRoutes} from './data-routes';

export const mainRoutes = {
	dashboard: '/dashboard',
	data: {
		root: '/data',
		...dataRoutes,
	},
	subjectManagement: '/subject-management',
	reports: '/reports',
	testExpolorer: '/test-expolorer',
	studentManager: '/student-manager',
	parentConferencer: '/parent-conferencer',
	exportData: '/export-data',
};
