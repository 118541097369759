import React, {useCallback, useState} from 'react';
import {
	Checkbox,
	Container,
	Content,
	Divider,
	Footer,
	Header,
	HiddenIcon,
	ImageWrapper,
	InfoWrapper,
	StyledButton,
	StyledImage,
	Title,
} from '../index.styled';
import {TestColorIndicator, TestContentArea} from '@esgi/main/kits/common';
import {Text} from '@esgi/ui/typography';
import {CardViewType, TestModel, TestType} from '../../../service/types';
import {TestTypeLabel} from '../../test-type-label';
import {MultipleLabels} from '../../multiple-labels';
import {BaseComponentProps, Fav, Tooltip} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {LabelBox} from '../../test-type-label/index.styled';
import {questionLabelByTestType, testLabelByTestType} from '../../../constants';
import {InfoItem} from './info-item';
import {TestType as TestTypeModel} from '@esgi/main/libs/core';
import {rubricThumbnail} from '../../questions-slider/constants';
import {QuestionsSlider} from '../../questions-slider';
import RubricPreview from 'modules/assets/tests/rubric/preview/preview-flow';
import {SpecialInfo} from './special-info';
import {StyledDescription} from './styled-description';
import {SubjectTab} from '@esgi/main/libs/store';
import moment from 'moment';
import {SubjectsCounter} from './subjects-counter';

type Props = BaseComponentProps & Omit<TestModel & {subjects: SubjectTab[]}, 'gradeLevels' | 'stateStandards'> & {
	onTestSelect: (testID: TestModel['testID']) => void,
	onAddToFavoriteClick: VoidFunction,
	showTestDetails: VoidFunction,
	showAddToFavorites,
	isHidden,
	selected: boolean,
	isSmallScreen: boolean,
	gradeLevels: string[],
	stateStandards: string[],
	cardView: CardViewType,
}

export function GridView({
	name,
	testID,
	type,
	contentArea,
	stateStandards,
	description,
	creatorName,
	gradeLevels,
	numberOfQuestions,
	starred,
	dataCy,
	selected,
	onTestSelect,
	onAddToFavoriteClick,
	showTestDetails,
	questions,
	testScreenTypes,
	totalPossible,
	showAddToFavorites,
	isHidden,
	createDate,
	subjects,
	cardView,
	imageQuestionID,
	isSmallScreen,
}: Props) {
	const [showRubricPreview, setShowRubricPreview] = useState(false);
	const showRubricPreviewToggle = useCallback(() => setShowRubricPreview(prev => !prev), []);

	return (
		<Container data-cy='test-card' selected={selected}>
			<Header>
				<InfoWrapper>
					<Checkbox data-cy='test-card-checkbox' checked={selected} onCheckedChange={() => onTestSelect(testID)} />
					<TestColorIndicator testID={testID} contentArea={contentArea as TestContentArea}/>
					<Title dataCy='test-name' size='xLarge' color={isHidden ? 'mediumContrast' : 'primary'} onClick={showTestDetails} css={{
						lineHeight: '22px',
					}}>{name}</Title>
					{isHidden && <HiddenIcon />}
				</InfoWrapper>
				<GridBox gap={3} flow='column'>
					<SubjectsCounter subjects={subjects}/>
					{showAddToFavorites && <Tooltip>
						<Tooltip.Trigger>
							<StyledButton checked={starred} onClick={onAddToFavoriteClick} dataCy='favorites-button'>
								<Fav/>
							</StyledButton>
						</Tooltip.Trigger>
						<Tooltip.Content side='top'>
							<Text size='large' color='tertiary'>
								{starred ? 'Remove from favorites' : 'Add to favorites'}
							</Text>
						</Tooltip.Content>
					</Tooltip>}
				</GridBox>
			</Header>
			<Content gridView={cardView === CardViewType.Grid}>
				{testLabelByTestType[type] === TestTypeModel.Rubric
					? <ImageWrapper data-cy='rubric-thumbnail'><StyledImage src={rubricThumbnail} onClick={showRubricPreviewToggle}/></ImageWrapper>
					: <QuestionsSlider imageQuestionID={imageQuestionID} questions={questions} testID={testID} cardView={cardView}/>
				}
				<GridBox gap={4}>
					<StyledDescription dataCy='test-description' description={description}/>
					<GridBox gap={3} flow='column' justify='start'>
						<GridBox data-cy='test-author' gap={2} flow='column' justify='start'>
							<Text data-cy='test-author-label' size='xSmall' font='mono'>By:</Text>
							<Text data-cy='test-author-name' size='xSmall' font='mono' color='highContrast'>{creatorName}</Text>
						</GridBox>
						<GridBox data-cy='test-creation' gap={2} flow='column' justify='start'>
							<Text data-cy='test-creation-label' size='xSmall' font='mono'>Date:</Text>
							<Text data-cy='test-creation-date' size='xSmall' font='mono' color='highContrast'>{moment(createDate).format('MM.DD.YY')}</Text>
						</GridBox>
					</GridBox>
				</GridBox>
			</Content>
			<Footer isSmallScreen={isSmallScreen}>
        <GridBox gap={3}>
          <InfoItem dataCy='test-type-grid-view' title='Test Type' viewType='grid'>
            <TestTypeLabel testType={type}/>
          </InfoItem>
          <InfoItem dataCy='content-area' title='Content Area' viewType='grid'>
	          <LabelBox>
		          <Text data-cy='content-area-name' size='small' color='highContrast'>{contentArea}</Text>
	          </LabelBox>
          </InfoItem>
          <InfoItem dataCy='grade-level' title='Grade Level' viewType='grid'>
	          <MultipleLabels dataCy='grade-level-names' items={gradeLevels}/>
          </InfoItem>
        </GridBox>
				<GridBox flow='column' gap={4} justify='start'>
					<Divider/>
					<GridBox gap={3} rows={3}>
						<InfoItem dataCy='standards' title='Standards' viewType='grid'>
							<MultipleLabels dataCy='standard-names' items={stateStandards}/>
						</InfoItem>
						<InfoItem dataCy='additional-test-info' title={questionLabelByTestType[TestType[type]]} viewType='grid'>
							<LabelBox>
								<Text data-cy='additional-test-info-value' size='small' color='highContrast'>
									{TestTypeModel[type] === TestTypeModel.Score ? totalPossible : numberOfQuestions}
								</Text>
							</LabelBox>
						</InfoItem>
						<SpecialInfo cardView={CardViewType.Grid} testScreenTypes={testScreenTypes}/>
					</GridBox>
				</GridBox>
      </Footer>
			{showRubricPreview && <RubricPreview testID={testID} onClose={showRubricPreviewToggle}/>}
		</Container>
	);
}
