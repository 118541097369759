import React, {forwardRef, ReactNode, useEffect, useMemo, useState} from 'react';
import {DPCalendar, useDatePicker} from '@rehookify/datepicker';
import {Skeletonable} from '../../../../types';
import {SkeletonStylesWithBorders} from '../../../../skeleton';
import {AddOffset, DayButtonType, DateFormat, SubtractOffset} from '../../types';
import {DatePickerContext, DatePickerContextValue} from '../../context';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {parseDateRangeToString} from '../../utils';
import {styled} from '@esgi/ui/theme';
import {Box} from '../../../../layout/box';
import {BaseComponentProps} from '@esgi/ui';
import {isEqual} from 'underscore';

type DatePickerRootProps = {
	/** Optional. Callback for when selected dates change. */
	onChange?: (values: Date[]) => void;

	/** Optional. If true, enables range date selection.
	 * @default false
	 * */
	rangeMode?: boolean;

	/** Optional. Change date format.
	 * @default mM/dD/YYYY
	 * */
	format?: DateFormat;

	/** Optional. Initial date(s) for the date picker. */
	value?: Date[];

	/** Optional. Indicates an error state for the date picker.
	 * @default false
	 * */
	error?: boolean;

	/** Optional. Date picker disable state.
	 * @default false
	 * */
	disabled?: boolean;

	/** Optional. Child components or a function returning a JSX element. */
	children?:
		| ReactNode
		| ((args: {
		calendar: DPCalendar;
		weekDays: string[];
		dayButton: DayButtonType;
		addOffset: AddOffset;
		subtractOffset: SubtractOffset;
	}) => React.JSX.Element);
} & BaseComponentProps & Skeletonable


export const DatePickerRoot = forwardRef<HTMLDivElement, DatePickerRootProps>(({
	children, rangeMode = false, format = 'mM/dD/YYYY', value, onChange, error = false, dataCy = 'ui-kit-date-picker-root', disabled, ...props
}, forwardedRef) => {

	const [selectedDates, setSelectedDates] = useState<Date[]>([]);
	const [expanded, setExpanded] = useState<boolean>(false);
	const [dateValue, setDateValue] = useState<string>('');
	const [approvedDate, setApprovedDate] = useState<Date[]>(value || []);
	const [isCalendarRendered, setCalendarRendered] = useState<boolean>(false);
	const [offsetDate, onOffsetChange] = useState<Date>(new Date());

	const {
		data: {calendars, weekDays, selectedDates: dateObjects},
		propGetters: {dayButton, addOffset, subtractOffset},
	} = useDatePicker({
		offsetDate,
		onOffsetChange,
		selectedDates,
		onDatesChange: setSelectedDates,
		dates: {
			toggle: true,
			mode: rangeMode ? 'range' : 'single',
		},
		calendar: {
			startDay: 1,
			mode: 'fluid',
		},
	});

	useEffect(() => {
		if(!isEqual(approvedDate, value)) {
			onChange?.(approvedDate);
		}
	}, [approvedDate]);

	useEffect(() => {
		if (value !== undefined) {
			setDateValue(parseDateRangeToString(value, format));
			setApprovedDate(value);
		}
	}, [value]);

	const context = useMemo<DatePickerContextValue>(
		() => ({
			expanded,
			calendars,
			weekDays,
			dayButton,
			addOffset,
			subtractOffset,
			setExpanded,
			rangeMode,
			dateValue,
			setDateValue,
			setSelectedDates,
			isCalendarRendered,
			setCalendarRendered,
			approvedDate,
			setApprovedDate,
			error,
			format,
			dateObjects,
			disabled,
		}),
		[
			expanded,
			setExpanded,
			rangeMode,
			setSelectedDates,
			dateValue,
			setDateValue,
			approvedDate,
			setApprovedDate,
			isCalendarRendered,
			setCalendarRendered,
			addOffset,
			subtractOffset,
			dayButton,
			weekDays,
			calendars,
			error,
			format,
			dateObjects,
			disabled,
		],
	);
	return <DatePickerContext.Provider value={context}>
		<Root data-cy={dataCy} {...props} ref={forwardedRef}>
			<DropdownMenu.Root open={expanded} onOpenChange={(open) => {
				setExpanded(open);
				if (approvedDate?.filter(Boolean)?.length) {
					setSelectedDates(approvedDate);
					onOffsetChange(approvedDate[0]);
				}
			}
			}>
				{typeof children === 'function'
					? children({
						calendar: calendars[0],
						weekDays,
						dayButton,
						addOffset,
						subtractOffset,
					})
					: children}
			</DropdownMenu.Root>
		</Root>

	</DatePickerContext.Provider>;
});

const Root = styled(Box, {
	position: 'relative',

	variants: {
		skeleton: {
			true: {
				...SkeletonStylesWithBorders,
			},
		},
	},
});
