import {DiagramBox} from '../../../../../../diagram-box';
import {ItemAnalysisDiagram, ItemAnalysisDiagramDetailed} from '../../../../../../item-analysis-diagram';
import {Container, Sidebar} from '../../../content.styled';
import {SynchronizedStateProps} from '../../types';
import {ToggleDiagramView} from '../toggle-diagram-view';
import {TabDescriptionBox} from './index.styled';
import {ActiveSelectedDiagramDescription} from '../active-selected-diagram-description';
import {YAxisLabel} from '../../constants';

type Props = SynchronizedStateProps & {
	onFullScreenButtonClick: VoidFunction;
};

export function QuickViewContent({
	activeTab,
	setActiveTab,
	isTablet,
	isDetailedView,
	onFullScreenButtonClick,
	emptySessionsCount,
	diagramLegendItems,
	testSessions,
	testInfo,
	diagramPageIndex,
	setDiagramPageIndex,
}: Props) {
	return (
		<Container isTablet={isTablet}>
			<Sidebar>
				<ToggleDiagramView activeTab={activeTab} setActiveTab={setActiveTab} />
				<TabDescriptionBox isTablet={isTablet}>
					<ActiveSelectedDiagramDescription isDetailedView={isDetailedView} />
				</TabDescriptionBox>
			</Sidebar>

			<DiagramBox
				legendItems={diagramLegendItems}
				withFullScreenButton
				onFullScreenButtonClick={onFullScreenButtonClick}
			>
				{isDetailedView ? (
					<ItemAnalysisDiagramDetailed
						sessions={testSessions}
						testInfo={testInfo}
						YAxisLabel={YAxisLabel}
						emptySessionsCount={emptySessionsCount}
						barWidth={160}
						barHeight={16}
						barsRowGap={20}
						pageIndex={diagramPageIndex}
						onPageIndexChanged={setDiagramPageIndex}
						isTablet={isTablet}
					/>
				) : (
					<ItemAnalysisDiagram
						sessions={testSessions}
						testInfo={testInfo}
						YAxisLabel={YAxisLabel}
						emptySessionsCount={emptySessionsCount}
						barLineWidth={20}
						diagramHeight={212}
						pageIndex={diagramPageIndex}
						onPageIndexChanged={setDiagramPageIndex}
					/>
				)}
			</DiagramBox>
		</Container>
	);
}
