import {ControlButton, Wrapper, ImageWrapper} from './index.styled';
import {Expand} from '@esgi/ui';
import {QuestionsSliderItem} from './components/questions-slider-item';
import {TestModel, QuestionModel, CardViewType} from '../../service/types';
import {useCallback, useEffect, useState} from 'react';
import {animated, useSpringRef, useTransition} from 'react-spring';

export type Props = {
	testID: TestModel['testID'],
	imageQuestionID: TestModel['imageQuestionID'],
	questions: QuestionModel[],
	cardView: CardViewType,
}

export function QuestionsSlider({questions, testID, cardView, imageQuestionID}: Props) {
	const transRef = useSpringRef();

	const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0);
	const [prevClicked, setPrevClicked] = useState(false);

	const transitions = useTransition(currentQuestionIdx, {
		ref: transRef,
		keys: null,
		from: {transform: `translateX(${prevClicked ? -50 : 100}%)`},
		enter: {transform: 'translateX(0%)'},
		leave: {transform: `translateX(${prevClicked ? 100 : -50}%)`},
		exitBeforeEnter: true,
		config: {
			duration: 200,
		},
	});

	const onPrevClick = useCallback(() => {
		setPrevClicked(true);
		setCurrentQuestionIdx( prev => prev === 0 ? questions.length - 1 : prev - 1);
	}, [questions]);

	const onNextClick = useCallback(() => {
		setPrevClicked(false);
		setCurrentQuestionIdx(prev => prev !== questions.length && prev !== questions.length - 1 ? prev + 1 : 0);

	}, [questions]);


	useEffect(() => {
		transRef.start();
	}, [currentQuestionIdx, transRef]);

	useEffect(() => {
		if (imageQuestionID) {
			setCurrentQuestionIdx(questions.findIndex(item => item.questionID === imageQuestionID));
			return;
		}
		setCurrentQuestionIdx(0);
	}, [imageQuestionID, questions]);

	return (
		<Wrapper data-cy='questions-slider'>
			<ControlButton data-cy='prev-button' disabled={questions.length === 1} onClick={onPrevClick} prev>
				<Expand/>
			</ControlButton>
				<ImageWrapper data-cy='preview-image'>
					{transitions((style, currentQuestionIdx) => (
						<animated.div style={style} key={questions[currentQuestionIdx]?.questionID}>
							<QuestionsSliderItem
								cardView={cardView}
								question={questions[currentQuestionIdx]}
								testID={testID}
							/>
						</animated.div>
					))}
				</ImageWrapper>
			<ControlButton data-cy='next-button' disabled={questions.length === 1} onClick={onNextClick} next>
				<Expand/>
			</ControlButton>
		</Wrapper>
	);
}