import {MutableRefObject, RefObject, useEffect, useRef, useMemo} from 'react';
import {BaseComponentProps, CheckAll} from '@esgi/ui';
import {OverlayScrollbarsComponentRef} from 'overlayscrollbars-react/types/OverlayScrollbarsComponent';
import {GridBox} from '@esgi/ui/layout';
import {Title} from '../index.styled';
import {StandardsSelectableList} from '../standards-selectable-list';
import {Skeleton} from '../skeleton';
import {NoItems} from '../no-items';
import {Text} from '@esgi/ui/typography';
import {Wrapper, SelectButton} from './index.styled';
import {OptionItem, StandardListItem} from '../../types';

type Props = BaseComponentProps & {
	standards: StandardListItem[],
	selectedStandards: OptionItem[],
	onStandardsSelect: (selected: OptionItem, hasChild?: boolean) => void,
	onStandardSelectMany: (isAdd: boolean) => void,
	isStandardsLoading: boolean,
	isDomainsLoading: boolean,
	onNextPage: VoidFunction,
	osRef: MutableRefObject<OverlayScrollbarsComponentRef<'div'>>;
}

export function StandardsList({
	standards,
	selectedStandards,
	onStandardsSelect,
	onStandardSelectMany,
	isStandardsLoading,
	isDomainsLoading,
	onNextPage,
	osRef,
}: Props) {
	const observerTarget: RefObject<HTMLDivElement> = useRef();

	useEffect(() => {
		if (!observerTarget.current) {
			return;
		}

		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0]?.isIntersecting) {
					onNextPage();
				}
			},
			{threshold: 0},
		);

		const target = observerTarget.current;

		if (target) {
			observer.observe(target);
		}

		return () => observer.unobserve(target);
	}, [onNextPage]);

	const isSelectedAll = useMemo(() => {
		const standatrdIDs = standards.flatMap(({standards}) => {
			return standards.flatMap(({id, children}) => [id, ...children.map(({id}) => id)]);
		});
		const selectedStandardIDs = selectedStandards
			.filter(({value}) => standatrdIDs.includes(value))
			.map(({value}) => value);
		return selectedStandardIDs.length === standatrdIDs.length;
	}, [standards, selectedStandards]);

	if (!isStandardsLoading && !isDomainsLoading && !standards?.length) {
		return (
			<NoItems message='Sorry, we do not have the state standards for the state you selected. Please choose another state or standard type'/>
		);
	}

	return (
		<Wrapper>
			<SelectButton color='tertiary' onClick={() => onStandardSelectMany(!isSelectedAll)}>
				<CheckAll/>
				<Text size='medium' bold>{ isSelectedAll ? 'Unselect' : 'Select' } All</Text>
			</SelectButton>
				<GridBox gap={isStandardsLoading ? 2 : 5}>
					{standards?.map(({name, standards}, idx) => (
						<GridBox gap={2} key={idx}>
							{name &&
								<Title font='mono' size='small' color='mediumContrast'>
									{name}
								</Title>
							}
							<StandardsSelectableList
								items={standards}
								selectedItems={selectedStandards.map(({value}) => value)}
								onStandardSelect={onStandardsSelect}
							/>
						</GridBox>))}
					{isStandardsLoading && <Skeleton variant='listItem'/>}
				</GridBox>
				<div ref={observerTarget} style={{height: 1}}/>
		</Wrapper>
	);
}
