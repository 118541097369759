import {GridBox} from '@esgi/ui/layout';
import {Container, DiagramBox, DiagramDescriptionBox, DiagramInfoBox, Divider, LegendBox} from './index.styled';
import {LegendWithTranscript} from '../../../../../../legend-with-transcript';
import {SynchronizedStateProps} from '../../types';
import {YAxisLabel} from '../../constants';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {ItemAnalysisDiagram, ItemAnalysisDiagramDetailed} from '../../../../../../item-analysis-diagram';
import {ToggleDiagramView} from '../toggle-diagram-view';
import {ActiveSelectedDiagramDescription} from '../active-selected-diagram-description';

export function FullScreenContent({
	activeTab,
	setActiveTab,
	isDetailedView,
	emptySessionsCount,
	diagramLegendItems,
	testSessions,
	testInfo,
	diagramPageIndex,
	setDiagramPageIndex,
	isTablet,
}: SynchronizedStateProps) {
	return (
		<Container>
			<GridBox flow='column' justify='between' align='center' gap='6'>
				<ToggleDiagramView activeTab={activeTab} setActiveTab={setActiveTab} />

				<GridBox>
					<LegendBox>
						{diagramLegendItems.map((props, index) => (
							<LegendWithTranscript key={index} {...props} />
						))}
					</LegendBox>
				</GridBox>
			</GridBox>

			<Divider />

			<DiagramInfoBox>
				<DiagramDescriptionBox>
					<ActiveSelectedDiagramDescription isDetailedView={isDetailedView} />
				</DiagramDescriptionBox>

				<DiagramBox justifyCentered={!isDetailedView}>
					<OverlayScrollbarsComponent
						defer
						options={{
							scrollbars: {autoHide: 'leave'},
							paddingAbsolute: true,
						}}
					>
						{isDetailedView ? (
							<ItemAnalysisDiagramDetailed
								sessions={testSessions}
								testInfo={testInfo}
								YAxisLabel={YAxisLabel}
								barWidth={376}
								barHeight={16}
								barsRowGap={24}
								diagramWidth='calc(100% - 12px)'
								emptySessionsCount={emptySessionsCount}
								pageIndex={diagramPageIndex}
								onPageIndexChanged={setDiagramPageIndex}
								isTablet={isTablet}
							/>
						) : (
							<ItemAnalysisDiagram
								sessions={testSessions}
								testInfo={testInfo}
								YAxisLabel={YAxisLabel}
								barLineWidth={16}
								diagramWidth={812}
								diagramHeight={352}
								withCartesianGrid
								emptySessionsCount={emptySessionsCount}
								pageIndex={diagramPageIndex}
								onPageIndexChanged={setDiagramPageIndex}
							/>
						)}
					</OverlayScrollbarsComponent>
				</DiagramBox>
			</DiagramInfoBox>
		</Container>
	);
}
