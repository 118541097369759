import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {storage, Class, Group, Student} from '@esgi/main/libs/store';

export class StudentService extends BaseService {
	public loaded$ = new BehaviorSubject(false);
	public readonly classes$ = new BehaviorSubject<Class[]>([]);
	public readonly groups$ = new BehaviorSubject<Group[]>([]);
	public readonly students$ = new BehaviorSubject<Student[]>([]);

	private readonly storageClasses = storage.classes();
	private readonly storageGroups = storage.groups();
	private readonly storageStudents = storage.students();

	constructor() {
		super();

		this.storageClasses.get().subscribe((value) => this.classes$.next(value));
		this.storageGroups.get().subscribe((value) => this.groups$.next(value));
		this.storageStudents.get().subscribe((value) => this.students$.next(value));
	}

	public override dispose() {
		this.storageClasses.dispose();
		this.storageGroups.dispose();
		this.storageStudents.dispose();
	}

	public get(studentId: number) {
		return this.students$.value.find(({id}) => id === studentId);
	}
}
